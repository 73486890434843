body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MenuItem {
  color: black;
  cursor: pointer;
  border-radius: 4px;
  text-transform: capitalize;
}
.MenuItem.selected {
  /* Increase the specificity */
  color: #3f51b5;
  background-color: #3f51b514 !important;
}
.smallIcon {
  font-size: 12px !important;
}

.App {
  font-family: sans-serif;
  text-align: center;
  background: skyblue;
}
h3 {
  font-weight: 200;
  text-shadow: 1px 1px 3px gray;
}
.container {
  height: 90vh;
  width: 80vw;
  margin: 0 auto;
  padding: 10px;
  display: flex;
  justify-content: space-evenly;
}
.small-box {
  width: 40%;
  box-shadow: 3px 3px 50px 2px gray;
}

.task {
  border: 1px solid white;
  margin: 10px;
  box-shadow: 1px 3px 20px gray;
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dragAreaBg {
  padding: 6px;
  background-color: #bae6fd;
  border: "1px solid #0ea5e9";
  border-radius: 5px;
  transition: background-color 0.5s linear;
}

.grabbing {
  cursor: grabbing;
}

[draggable] {
  user-select: none;
}

.dragged {
  background-color: rgba(200, 200, 200, 0.3);
  /* background-color: red !important; */
  opacity: 0.5;
  /* width: "80px" !important; */
  cursor: pointer;
}
.dragged-over-builder {
  background-color: #e2e8f0 !important;
  transition: background-color 0.5s linear;
  border: 2.3px dashed #94a3b8;
  transition: background-color 0.5s linear;
}
/* .dragged-over > .childDiv {
  background: #3f51b524 !important;
} */
.task {
  background: rgba(255, 0, 0, 0.1);
}

.inputHidden {
  background: transparent;
  border: none;
  text-decoration: none;
  padding: 6px;
  width: 94%;
  text-transform: capitalize;
}

.inputHidden:focus {
  outline: none;
  background-color: #ebebeb5c;
}

.component-list-over {
  /* background: #3f51b524 !important; */
  border: 1.4px dashed #3f51b5 !important;
}

/*
 *  scroll-bar
 */

* ::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  border-radius: 10px;
  /* background-color: #F5F5F5; */
}

* ::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  /* background-color: #F5F5F5; */
}

* ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
  background-color: #e2e8f0;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.loading {
  animation: spin 2s linear infinite;
}

.selectedComponent {
  background-color: #017afe21;
  /* border: 1px solid #017afe3d !important; */
  border: 4px solid #017afe !important;
}
.selectedScreen {
  background-color: rgb(195 220 255) !important;
}

.qdmList {
  background-color: #ffedd5 !important;
  padding: 5px 0px !important;
}

.hide {
  /* display: none !important; */
  transform: scale(0.9);
  opacity: 0.5;
  background: aliceblue;
}

img {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  user-drag: none;
  -webkit-touch-callout: none;
}

/* .selectedScreen > .fontIcon{
  color: #fff !important;
} */

/* // Typing style */
.typing {
  animation: fadeIn 10s infinite;
  -webkit-animation: fadeIn 10s infinite;
  -moz-animation: fadeIn 10s infinite;
  -o-animation: fadeIn 10s infinite;
  -ms-animation: fadeIn 10s infinite;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.MuiTable-root {
  border-collapse: unset !important;
}

* {
  border-collapse: unset !important;
}

html {
  border-collapse: unset !important;
}

.MuiTableHead-root {
  display: block !important;
}

.MuiTableRow-root {
  display: block !important;
}

.MuiTableBody-root {
  display: block !important;
}

.MuiTableFooter-root {
  display: block !important;
}

.custom_material_ui_header header {
  position: relative !important;
}

.custom_material_ui_header .MuiDrawer-paper {
  position: relative !important;
}

.custom_material_ui_drawer_body {
  display: flex !important;
}

.custom_material_ui_drawer_body .custom-material-ui-component-MuiDrawer-paper {
  display: flex !important;
  position: relative !important;
}

/* main */
